<template>
  <div class="login-container">
    <div style="position: absolute; width: 100%; text-align: center; top: 5em;">
      <img src="../assets/logo.png" width="100px">
    </div>
    <v-card class="login-card" elevation="10" max-width="340px">
      <h2>Login Panel</h2>
      <div class="sub-header">
        The most secured admin panel for Point Of View.
      </div>
      <v-form class="login-form" @submit="(event) => this.submitLogin(event)">
        <input
          type="email"
          v-model="email"
          name="email"
          placeholder="E-mail Address"
          required
        />
        <span v-if="requestInvalid" class="invalid-feedback" role="alert">
          These credentials do not match our records.
        </span>
        <input
          type="password"
          v-model="password"
          name="password"
          placeholder="Password"
          required
        />
        <button type="submit" class="submit-btn">Login</button>
      </v-form>
    </v-card>
    <img class="login-card-backdrop"
         src="../assets/graphic3.svg"
    />
  </div>
</template>

<script>
import Api from "../plugins/Api";
import router from '../router';
import axios from 'axios';

export default ({
  name: "LoginView",
  data() {
    return {
      requestInvalid: false,
      password: "",
      email: "",
    };
  },
  mounted() {
  },
  methods: {
    submitLogin(event) {
      event.preventDefault();
      const isValid = event.srcElement.reportValidity();
      const $this = this;

      if (!isValid) {
        return;
      }

      axios
        .post(`${Api.Host()}/api/login`, {
          email: this.$data.email,
          password: this.$data.password,
        })
        .then(function (response) {
          $this.$store.commit('LOGIN_SUCCESS', response.data.data)
          $this.$data.requestInvalid = false;
          router.push({name: 'admin'});
        })
        .catch(function (error) {
          console.error(error);
          $this.$data.requestInvalid = true;
        });
    },
  },
});
</script>

<style lang="scss">
h2 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
}
.login-container {
  position: relative;
  background: #57d38c;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .invalid-feedback {
    color: #dc3545;
    font-size: 80%;
    font-weight: bolder;
    font-family: "Lato", sans-serif;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    > input {
      border: 0;
      background-color: #f7f7f7;
      padding: 9px 20px;
      border-radius: 6px;
      margin-bottom: 14px;
      width: 100%;
      transition: all 0.3s ease;
      font-size: 15px;
      font-weight: 300;

      &:focus-visible {
        outline: none;
      }

      &:hover,
      &:focus {
        background-color: #eaeaea;
      }
    }

    .submit-btn {
      background-color: #57d38c;
      color: #fff;
      //box-shadow: 0 0 0 rgb(0 0 0 / 16%);
      border-radius: 6px;
      border: 0;
      font-weight: bold;
      font-size: 14px;
      padding: 6px 28px;
      margin-top: 20px;
      width: fit-content;

      &:hover {
        //box-shadow: 0 5px 6px rgb(0 0 0 / 16%);
      }
    }
  }

  .login-card-backdrop,
  .login-card {
    position: absolute;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .login-card-backdrop {
    max-width: 550px;
  }

  .login-card {
    z-index: 1;
    padding: 35px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;

    .sub-header {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
    }
  }
}
</style>
